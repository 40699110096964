import { useState } from "react";
import editIcon from "assets/icons/edit.svg";
import coinsIcon from "assets/icons/money.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import SharedRating from "modules/Shared/SharedRating/SharedRating";
import { Link } from "react-router-dom";
import "./ViewProfile.scss";
import { useEffect } from "react";
import userAvatar from "assets/images/Group 2354.svg";
import { useSearchParams } from "react-router-dom" 
import { toastError, toastSuccess } from "helpers/toasterFun";

const ViewProfile = ({
	imgSrc,
	userData,
	setShowEdit,
	accountType = "pro",
	setBuyTokenModalVisibility,
	service,
}) => {
	const [serviceNameUpdated, setServiceNameUpdated] = useState(null);
	const {
		authorization: { authorization },
	} = useSelector((state) => ({
		authorization: state.authorization,
		lang: state.lang,
	}));
	const { phone, email, address, bio, website, coins, rating, serviceName } =
		userData;

	useEffect(() => {
		if (serviceName) setServiceNameUpdated(serviceName);
		if (service) setServiceNameUpdated(service);
	}, [service, serviceName]);

	const { t } = useTranslation();
  const paidState = useSearchParams()[0].get("p") 

  useEffect(()=>{
    if (t("paidSuccessfullyAndPointsAdded") !== "paidSuccessfullyAndPointsAdded"){
      if(paidState === "1"){
        toastSuccess(t("paidSuccessfullyAndPointsAdded"));
      } else if (paidState === "0"){
        toastError(t("errorHappendInThePayment"));
      }
    }
  },[t, paidState])
	return (
		<>
			<figure className="sidebar__figure">
				<img
					src={imgSrc}
					onError={(e) => {
						e.target.src = userAvatar;
						e.target.alt = "Image failed to load";
					}}
					alt="userAvatar"
				/>
			</figure>
			<header className="sidebar__header">
				<h2>{userData.userName}</h2>
				{serviceNameUpdated ? <p>{serviceNameUpdated}</p> : null}
			</header>
			{accountType === "pro" && (
				<>
					<div className="sidebar__content first-step">
						<p className="sidebar__content--bio">{authorization?.bio || bio}</p>
						<h4 className="sidebar__content--website">
							{authorization?.website || website}
						</h4>
						<div className="sidebar__content--details">
							<p>{authorization?.phone || phone}</p>
							<p>{authorization?.email || email}</p>
							<p>{authorization?.address || address}</p>
						</div>
					</div>
					<div className="sidebar__rating mb-2">
						<header className="sidebar__rating--header">
							<h6>{t("overAllRating")}</h6>
						</header>
						<div className="stars">
							<SharedRating readOnly value={rating} />
						</div>
					</div>
					<div className="sidebar__money mb-4 d-flex align-items-center justify-content-center">
						<p role="button" onClick={() => setBuyTokenModalVisibility(true)}>
							{coins || 0}
						</p>
						<img
							style={{
								width: "4rem",
							}}
							src={coinsIcon}
							alt="money-icon"
							role="button"
							onClick={() => setBuyTokenModalVisibility(true)}
						/>
					</div>
				</>
			)}

			<div className="sidebar__edit d-flex align-items-center justify-content-center">
				<div className="d-flex align-items-center justify-content-center mb-4">
					<p
						className="me-2"
						role="button"
						onClick={() => {
							setShowEdit(true);
						}}
					>
						{t("editProfile")}
					</p>
					<img
						src={editIcon}
						alt="edit-profile"
						role="button"
						onClick={() => {
							setShowEdit(true);
						}}
					/>
				</div>
			</div>
			{accountType !== "pro" && (
				<div className="my-4">
					<Button
						className="px-3"
						style={{
							height: 45,
						}}
						type="primary"
					>
						<Link to="/profile/create-project" className="stretched-link">
							{t("createNewProject")}
						</Link>
					</Button>
				</div>
			)}
		</>
	);
};

export default ViewProfile;
