import { getResource, postResource } from "services";

export function signIn(data, lang, onSuccess, onFail) {
	const path = "api/v1/login?lang=" + lang;
	postResource(path, data, onSuccess, onFail, false);
}

export function signUp(data, lang, onSuccess, onFail) {
	const path = "api/v1/register?lang=" + lang;
	postResource(path, data, onSuccess, onFail, false);
}

export function getAllAccountTypes(lang, onSuccess, onFail) {
	const path = `api/v1/getRoles?lang=${lang}`;
	getResource(path, onSuccess, onFail, false);
}
