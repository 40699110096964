import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
	Row,
	Col,
	Input,
	Space,
	Checkbox,
	Form,
	Radio,
	Button,
	Spin,
} from "antd";
import { signUp } from "../network";
import { toastError, toastSuccess, toastTime } from "helpers/toasterFun";
import TermsAndConditionsModal from "../components/TermsAndConditions/TermsAndConditions";
import PrivacyAndPolicy from "../components/PrivacyAndPolicy/PrivacyAndPolicy";
// icons
import closeIcon from "assets/icons/x.svg";
import { getAllAccountTypes } from "./../network";

import "../authorization.scss";
import { useTranslation } from "react-i18next";

const PropertyOwnerID = '625be6db995eb93c8f7bfdbf';

const Signup = ({ setCurrentView, guestProject, setOpenModal }) => {
	const [form] = Form.useForm();

	const [termsAndConditions, setTermsAndConditions] = useState(false);
	const [userTypes, setUserTypes] = useState(null);
	const [selectedUserType, setSelectedUserType] = useState(null);
	const [agreeTermsAndConditions, setAgreeTermsAndConditions] = useState(false);
	const [privacyAndPolicy, setPrivacyAndPolicy] = useState(false);
	const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] =
		useState(false);
	const [timeOutToUse, setTimeOutToUse] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [showTermsPrivacyPolicyModal, setShowTermsPrivacyPolicyModal] =
		useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { lang } = useSelector((state) => state.lang);

	useEffect(() => {
		setIsDisabled(true);
		getAllAccountTypes(
			lang,
			(success) => {
				const { data } = success;
				if (setCurrentView) setUserTypes(data?.filter(d => d._id === PropertyOwnerID));
				else setUserTypes(data);
				if (setCurrentView) setSelectedUserType(PropertyOwnerID);
        else setSelectedUserType(data[0]._id);
				setIsDisabled(false);
			},
			(fail) => {
				setIsDisabled(false);
			}
		);
		return () => {
			clearTimeout(timeOutToUse);
		};
	}, [setCurrentView, timeOutToUse, lang]);

	function onFinish(values) {
		if (privacyAndPolicy && agreeTermsAndConditions) {
			setIsLoading(true);
			const { firstName, lastName, email, password, phone } = values;
			let data = {
				name: firstName + " " + lastName,
				firstName,
				lastName,
				phone,
				email: email.trim(),
				password: password.trim(),
				accountType: selectedUserType,
				lang,
        guestProject: guestProject
			};
			signUp(
				data,
				lang,
				(success) => {
					const {
						data: { message },
					} = success;
					toastSuccess(message);
					setIsLoading(false);
					setTimeOutToUse(
						setTimeout(() => {
							navigate("/signIn");
						}, toastTime)
					);
				},
				(fail) => {
					setIsLoading(false);
					if (fail?.data.message) {
						toastError(fail.data.message);
					} else if (fail.data?.error) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		} else {
			setTermsAndConditions(true);
		}
	}

	// function for freezing inputs
	function unfreezeInput(e) {
		// eslint-disable-next-line
		e.target.value = e.target.value;
	}

	return (
		<>
			<main className="auth with__spinner">
				{isDisabled && (
					<div className="spinner__container">
						<Spin className="spinner" />
					</div>
				)}
				<section className="auth__section signup__section">
          {setCurrentView&&setOpenModal ?
          <Button onClick={() => setOpenModal(false)} style={{border:"none", display:"contents"}}>
            <img src={closeIcon} alt="close-signup" className="close-popup" />
          </Button>:
					<Link to="/">
						<img src={closeIcon} alt="close-signup" className="close-popup" />
					</Link>
          }
					<header className="auth__head">
						<h3 className="auth__title">{t("signUp")}</h3>
					</header>
					<div className="auth__form-content">
						<div className="new-user">
							<div className="new-user__text">{t("alreadyAUser")}</div>
							<div className="new-user__create">
                {setCurrentView ? <Button className="auth__btn__anchor" onClick={() => setCurrentView("signin")}>{t("signIn")}</Button> :
								<Link to="/signin">{t("signIn")}</Link>
                }
							</div>
						</div>
						<div className="auth__user-type d-flex">
							{userTypes?.length && (
								<Radio.Group
									onChange={(e) => {
										setSelectedUserType(e.target.value);
									}}
									value={selectedUserType}>
									{userTypes?.map((item) => (
										<Radio value={item._id} key={item._id}>
											{item.accountType}
										</Radio>
									))}
								</Radio.Group>
							)}
						</div>
						{/*  onSubmitCapture={submitSignup} */}
						<Form className="form" form={form} onFinish={onFinish} key={lang}>
							<Space size="small" direction="vertical">
								<Row gutter={[{ md: 32 }]}>
									<Col xs={24} md={12}>
										<Form.Item
											className="form__item"
											label={
												selectedUserType === "625be6e5995eb93c8f7bfdc1"
													? t("firstName_companyName")
													: t("firstName")
											}
											name="firstName"
											colon={false}
											rules={[
												{
													required: true,
													message: `${
														selectedUserType === "625be6e5995eb93c8f7bfdc1"
															? t("pleaseProvideFirstNameCompanyName")
															: t("pleaseProvideFirstName")
													}
                          `,
												},

												{
													min: 3,
													message: t("pleaseProvideValidName"),
												},
											]}>
											<Input
												placeholder={`${
													selectedUserType === "625be6e5995eb93c8f7bfdc1"
														? t("firstName_companyName")
														: t("firstName")
												}
                          `}
												bordered={false}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item
											className="form__item"
											label={t("lastName")}
											name="lastName"
											colon={selectedUserType !== "625be6e5995eb93c8f7bfdc1"}
											rules={[
												{
													required:
														selectedUserType !== "625be6e5995eb93c8f7bfdc1",
													message: t("provideLastName"),
												},
												{
													min: 3,
													message: t("pleaseProvideValidName"),
												},
											]}>
											<Input
												id="lastName"
												placeholder={t("lastName")}
												bordered={false}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item
											className="form__item"
											label={t("email")}
											name="email"
											colon={false}
											rules={[
												{
													required: true,
													message: t("provideYourEmail"),
												},
												{
													type: "email",
													message: t("provideValidEmail"),
												},
											]}>
											<Input
												placeholder={`${
													selectedUserType === "625be6e5995eb93c8f7bfdc1"
														? "Pro"
														: "Client"
												}@mail.com`}
												className="form-underline"
												bordered={false}
												dir={"ltr"}
												style={{
													direction: "ltr",
												}}
												onClick={(event) => unfreezeInput(event)}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item
											className="form__item"
											label={t("password")}
											name="password"
											colon={false}
											rules={[
												{
													required: true,
													message: t("pleaseProvideStrongPassword"),
												},
												{
													min: 6,
													message: t("pleaseProvideAtLeast6Characters"),
												},
											]}>
											<Input.Password
												onClick={(event) => unfreezeInput(event)}
												placeholder="******"
												bordered={false}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item
											label={t("phoneNumber")}
											className="form__item"
											name="phone"
											colon={false}
											rules={[
												{
													required: true,
													message: t("pleaseProvideYourPhoneNumber"),
												},
											]}>
											<Input
												itemType="number"
												type="number"
												id="phone"
												placeholder="+20 123 456 7893"
												bordered={false}
											/>
										</Form.Item>
									</Col>
								</Row>
								<div
									className={`form__keep ${termsAndConditions ? "alert" : ""}`}>
									<p className="ms-2">{t("iAgreeTo")}</p>
									<div>
										<Checkbox
											onChange={() =>
												setAgreeTermsAndConditions((prev) => !prev)
											}
											className="form__checkbox--checked"
											checked={agreeTermsAndConditions}
										/>
										<label onClick={() => setShowTermsAndConditionsModal(true)}>
											{t("termsAndConditions")}
										</label>
									</div>
									<div>
										<Checkbox
											onChange={() => setPrivacyAndPolicy((prev) => !prev)}
											className={`form__checkbox--checked`}
											checked={privacyAndPolicy}
										/>
										<label onClick={() => setShowTermsPrivacyPolicyModal(true)}>
											{t("privacyPolicy")}
										</label>
									</div>
								</div>
								<Form.Item className="primary-bid-btn">
									<Button
										type={"primary"}
										loading={isLoading}
										className="btn auth-btn"
										htmlType="submit">
										{t("SignUp")}
									</Button>
								</Form.Item>
							</Space>
						</Form>
					</div>
				</section>
			</main>
			{showTermsAndConditionsModal && (
				<TermsAndConditionsModal
					showTermsModal={showTermsAndConditionsModal}
					onCancel={() => {
						setShowTermsAndConditionsModal(false);
					}}
					setAgreeTermsAndConditions={setAgreeTermsAndConditions}
				/>
			)}
			{showTermsPrivacyPolicyModal && (
				<PrivacyAndPolicy
					showTermsModal={showTermsPrivacyPolicyModal}
					onCancel={() => {
						setShowTermsPrivacyPolicyModal(false);
					}}
					setAgreeTermsAndConditions={setPrivacyAndPolicy}
				/>
			)}
		</>
	);
};

export default Signup;
