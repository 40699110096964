export const ar = {
	kickOffProject: "قم ببدء مشروعك الآن",
	hireYourContractor: "عين مقاولك",
	service: "الخدمات المهنية",
	howItWorks: "طريقة الاستخدام",
	homeService: "خدمات منزلية",
	blog: "مقالات",
	contactUs: "تواصل معنا",
	faqs: "الأسئلة الشائعة",
	signIn: "تسجيل الدخول",
	signOut: "الخروج",
	SignUp: "إنشاء حساب",
	signUpSignIn: "إنشاء حساب/ الدخول",
	english: "English",
	arabic: "العربية",
	profile: "الحساب الشخصي",
	searchFor: "البحث",
	settings: "الإعدادات",
	firstName: "الإسم الأول",
	lastName: "اسم العائلة",
	email: "البريد الإلكتروني",
	password: "كلمة المرور",
	phone: "رقم الهاتف",
	profileSettings: "إعدادات الملف الشخصي",
	changePassword: "تغيير كلمة المرور",
	saveChanges: "إحفظ التغييرات",
	firstNameCompany: "الإسم الأول",
	phoneNumber: "رقم الهاتف",
	allowNotifications: "السماح بالإشعارات",
	oldPassword: "الرقم السري القديم",
	newPassword: "الرقم السري الجديد",
	confirmNewPassword: "تأكيد الرقم السري",
	resetPassword: "تغيير الرقم السري",
	// validations
	provideFirstName: "من فضلك أدخل الإسم الأول.",
	provideLastName: "من فضلك أدخل اسم العائلة .",
	provideValidName: "من فضلك أدخل الإسم حقيقي.",
	provideYourEmail: "من فضلك أدخل بريدك الإلكتروني.",
	provideValidEmail: "من فضلك أدخل بريد إلكتروني صحيح.",
	providePhoneNumber: "من فضلك أدخل رقم الهاتف.",
	provideOldPassword: "من فضلك أدخل الرقم السري القديم .",
	provideNewPassword: "من فضلك أدخل الرقم السري الجديد",
	provideAtLeast6chars: "علي الأقل 6 أحرف.",
	passwordDoesNotMatch: "الرقم السري غير متطابق",
	pleaseConfirmPassword: "من فضلك أكد الرقم السري.",
	bothPasswordsMustMatch: "كلا الأرقام السرية لابد وأن تتطابق",
	// sidebar
	overallRating: "التقييم الكلي",
	hirePro: "وظف",
	// service type prefereances
	submit: "إرسال",
	edit: "تعديل",

	// singin
	newUser: "مستخدم جديد ؟",
	createAnAccount: "أنشئ حساب",

	// create project
	projectName: "اسم المشروع",
	other: "أخري",
	pleaseSpecify: "من فضلك حدد",
	exactProjectArea: "مساحة المشروع بدقة",
	thisFieldIsRequired: "هذا الحقل مطوب",
	exactAreaMustBe: "50m2 مساحة المشروع لابد وأن تكون",
	projectAdress: "عنوان المشروع",
	projectArea: "مساحة المشروع",
	m: "م",
	handoverRequirements: "متطلبات التسليم",
	projectLocation: "مكان المشروع *",
	projetStatus: "حالة المشروع *",
	paymentPreferences: "تفضيلات الدفع *",
	uploadDrawings: "تحميل الرسومات",
	disclaimer: "ملاحظات",
	extraComment: "تعليقات إضافية",
	optionalAddionalConsultationServicesProvidedBy:
		"خدمات إستشارية إضافية إختيارية مقدمة من",
	confirmation: "التأكيد",
	areYouSureYouWantToSubmit: "هل أنت متأكد أنك تريد أن تقدم؟",
	ifYouSubmitThisProject: "إذا قمت بإرسال هذا المشروع ، فلا يمكنك تعديله.",
	pleaseChooseBetweenResidentialOrCommercial:
		"الرجاء الإختيار بين سكني أو تجاري.",
	pleaseSelectHandoverRequirement: "من فضلك ، حدد شرط التسليم.",
	pleaseProvideYourExactArea: "من فضلك ، حدد مساحة المشروع.",
	pleaseProvideYourServiceType: "من فضلك ، قدم نوع خدمتك.",
	pleaseFillAllTheRequiredFields: "من فضلك ، إملأ جميع الحقول المطلوبة.",
	waitTillProjectDrawingsUploaded: "إنتظر حتى يتم تحميل رسومات المشروع",
	pleaseUploadProjectDrawings: "من فضلك ، قم بتحميل رسومات المشروع.",
	drawingMustBeLessThan: "يجب أن تكون رسوماتك أقل من",
	mb: "ميجا",
	uploadedSuccessFully: "تم الرفع بنجاح.",
	// subnav
	portofolio: "موجز الأعمال السابقة",
	projectRoom: "المشاريع",
	serviceTypePreferances: "نوع الخدمة المقدمة",
	projectStats: "إحصائية المشاريع",
	ratingAndReviews: "التقييمات",
	projectsRoom: "المشاريع",
	createProject: "إنشاء مشروع جديد",
	ratePro: "قيم العميل",
	maxNumberOfCharactersIs: "أقصى عدد للحروف هو ",
	typeYourExtraCommentHere: "أكتب تعليقاً إضافيا ...",
	pleaseFillProjectNameField: "من فضلك, أدخل أسم المشروع ",
	projectCreatedSuccessfully: "تم إنشاء المشروع بنجاح .",
	thereAreNoProjects: "لا يوجد مشاريع حتي الآن",
	newProjects: "مشاريع جديدة",
	bids: "العطاءات",
	shortlisted: "ملخص المشروع",
	quotationSubmitted: "عروض الأسعار",
	projectDeletedSuccessfully: "تم مسح المشروع بنجاح",
	areYouSureYouWantToDeleteProject: "هل أنت متأكد من مسح ذلك المشروع؟",
	deleteProject: "حذف المشروع",
	delete: "حذف",
	cancel: "إلغاء",
	serviceType: "نوع الخدمة",
	bid: "عطاء",
	message: "ملحوظة",
	pendingClientShortlisting: "تعليق لحين الإختيار من قائمة المختارين",
	explainingThatProNeedToDownloadOffer:
		"توضيح أن المحترف يحتاج إلى تنزيل العرض",
	downloadTemplateBelow:
		"قم بتنزيل النموذج أدناه ثم قم بتحميله بعد ملئه وإرساله إلى العميل.",
	offerTemplate: "نموذج العرض",
	dragFileToUpload: "إسحب الملف للتحميل",
	offerUploadedSuccessfully: "عرض تم تحميله بنجاح",
	pendingTilClientHiringDecision: "في إنتظار قرار تعيين العميل.",
	hired: "عملت",
	notHired: "لم تعمل",
	letUsKnowIfYouAre: "إسمحوا لنا أن نعرف إذا كنت:",
	buyTokens: "شراء عملات رمزية",
	areYouSureWantBidInProject: "هل أنت متأكد أنك تريد التقديم علي هذا المشروع",
	ifYouStartBidding: "إذا بدأت العطاء سوف تبدأ الأمور ف السير باستخدام",
	tokens: "من العملات الرمزية",
	youDontHaveEnoughTokens: "ليس لديك عدد العملات التي يحتاجها هذا المشروع",
	doYouWantToBuyTokens: "هل تحتاج إلي شراء عملات لبدء العطاء؟",
	uploadedDrawings: "تحميل الرسومات/اللوحات",
	uploadedOffer: "تحميل عرض السعر",

	alreadyAUser: "بالفعل مستخدم ؟",
	firstName_companyName: "الإسم الأول | اسم الشركة",
	// profileSettings
	provideYourPassword: "من فضلك أدخل الرقم السري.",
	forgetPassword: "نسيت الرقم السري ؟",
	keepMeSignIn: "تذكرني",
	pleaseProvideFirstNameCompanyName: "من فضلك أدخل الإسم الأول | إسم الشركة",
	pleaseProvideFirstName: "من فضلك أدخل الإسم الأول. ",
	pleaseProvideValidName: "من فضلك أدخل اسم صحيح.",
	pleaseProvideAStrongPassword: "من فضلك أدخل رقم سر قوي.",
	pleaseProvideAtLeast6Characters: "علي الأقل مكون من 6 أحرف.",
	pleaseProvideYourPhoneNumber: "من فضلك أدخل رقم الهاتف.",
	iAgreeTo: "أنا موافق علي",
	termsAndConditions: "الأحكام والشروط",
	privacyPolicy: "الخصوصية والسياسة",
	iAgree: "أنا موافق",
	// subnav
	pleaseFillAllRequiredFields: "من فضلك أجب علي كل الأسئلة .",
	thereAreNoPorjects: "لا يوجد مشاريع",
	shortlisting: "القائمة المختارة",
	submittedBrief: "ملخص المشروع",
	profileAndQuotations: "الملفات و الأسعار",
	thereAreNoBiddersYet: "لا يوجد أحد قدم حتي الآن.",
	selectPackage: "أختر الحزمة",
	pound: "جنيه",
	selectPaymentMethod: "إختر طريقة الدفع",
	proceedWithPayment: "المضي قدما في الدفع",
	cridet_depateCard: "بطاقة إئتمان / الخصم",
	paypal: "بايبال",
	candidate: "مُرَشَّح",
	shortlistedSuccessfully: "تمت الإضافة للقائمةالمختارة",
	youNeedToShortlisted3Candidates: "تحتاج  إلي 3 مُرَشَّحين للإرسال",
	shortlistYourTop3Candidates: "ضع أفضل 3 مرشحين بالنسبة لك لإستلام",
	theirQuotationsForYou: "عروض الأسعار الخاصة بـ",
	project: "المشروع",
	shortlistedCandidatesProfileAndQuotations:
		"ملفات و أسعار المُرَشَّحين المختاين",
	buyToken: "شراء عملات رمزية",
	downloadOffer: "تنزيل العرض",
	enterProjectName: "أدخل أسم المشروع",
	projectAddedSuccessfully: "تم إضافة المشروع بنجاح",
	pleaseAddProjectName: "من فضلك أدخل أسم المشروع",
	pleaseSelectDate: "من فضلك أختر تاريخ المشروع",
	pleaseUploadYourAlbum: "من فضلك أرفع ألبوم الصور",
	projectUpdatedSuccessfully: "تم تعديل المشروع بنجاح",
	selectDate: "أختار التاريخ",
	pleaseRateTheProAccordingToTheFollowingCriteria:
		"من فضلك قيم العميل بناء علي أن الرقم 1 يعني ”ضعيف جدا“ و الرقم 5 تعني ”ممتاز“",
	noteThatYouCanOnlyRateOnePro:
		"* لاحظ أنك تستطيع تقييم المحترف لمرة واحدة فقط",
	noFinalizeProjectsYet: "لا توجد مشاريع انتهت حتي الآن",
	selectAPerson: "إختر شخص",
	projectThrough: "مشروع",
	projectsThrough: "مشاريع",
	thisProfileHasBeenAwarded: "هذا الحساب تم تقييمه من خلال",
	theresNoRatingForYou: "لا يوجد تقيم لهذا المحترف حتي الان",
	youDontHaveRating: "لا يوجد تقيمات لك حتي الان",
	by: "عن طريق",
	proIsRatedSuccessfully: "تم تقييم المحترف بنجاح",
	jan: "يناير",
	feb: "فبراير",
	mar: "مارس",
	apr: "إبريل",
	may: "مايو",
	jun: "يونيو",
	jul: "يوليو",
	aug: "أغسطس",
	sep: "سبتمبر",
	oct: "أكتوبر",
	nov: "نوفمبر",
	dec: "ديسمبر",
	matched: "متطابق",
	enteredBid: "الدخول في العطاءات",
	shortListed: "القائمة المختصرة",
	awarded: "مكافئات",
	numberOfProjects: "عدد المشاريع",
	overAllRating: "التقييم الكلي",
	over: "علي",
	performanceAccordingToRatingAndReviews: "الأداء نتيجة التقييمات و المراجعات",
	doYouHaveAnySuggesstionForUs: "9. هل لديك أي أقتراح ؟",
	yes: "نعم",
	no: "لا",
	ifYesFeedback: "من فضلك أكتب أقتراحك.",
	saveProject: "حفظ المشروع",
	makeAlbumCover: "وضع كـغلاف",
	editProfile: "تعديل الملف الشخصي",
	createNewProject: "إنشاء مشروع جديد",
	passwordChangedSuccessfully: "تم تغيير الرقم السري بنجاح.",
	userDataChangedSuccessfully: "تم تغيير معلوماتك بنجاح.",
	pleaseProvideLastName: "من فضلك أدخل اسم العائلة.",
	pleaseProvideYourName: "من فضلك أدخل اسمك.",
	companyProName: "أكتب اسم الشركة / اسم المحترف",
	writeYourBio: "أكتب السيرة الذاتية",
	writeYourWebsite: "أكتب موقعك الإلكتروني",
	yourAddress: "أكتب عنوانك",
	provideValidUrl: "من فضلك أدخل موقع إلكتروني صحيح.",
	getInTouch: "تواصل معنا",
	ourTeamIsHappy: "فريقنا سعيد للإجابة على أسئلتك.",
	fillOutTheForm: "إملأ النموذج وسنتواصل معك في أقرب وقت ممكن.",
	name: "الإسم *",
	inTouchEmail: "بريدك الإلكتروني *",
	yourMessage: "رسالتك *",
	pleaseProvideYourMessage: "من فضلك أضف رسالتك.",
	send: "إرسال",
	utilitiesAndMore: "خدمات أخري",
	haveAnyThingNeedFix: "لديك أي شيء يحتاج إلى الإصلاح أو الإعداد أو النقل.",
	keepYourPlace:
		"حافظ على مكانك بالترتيب وتصفح من خلال مجتمع المحترفين لدينا ، من عمال اليد إلى شركات النقل.",
	yourName: "أسمك",
	changeImage: "تغيير الصورة الشخصية",
	date: "التاريخ",
	editProjectTitle: "تعديل اسم المشروع",
	duplicateProject: "نسخ المشروع",
	pleaseAddProjectToYourPortofolio: "من فضلك أضف مشاريع لموجز أعمالك.",
	albums: "الألبومات",
	pendingApproval: "مازال يحتاج للموافقة",
	proDontHaveAnyProjects: "المحترف ليس عنده مشاريع في ملف الأعمال حتي الآن",
	pendingFiles: "ملفات معلقة",
	pendingFile: "ملف معلق",
	confirmPassword: "تأكيد الرقم السري",
	signUp: "إنشاء حساب",
	pleaseProvideStrongPassword: "من فضلك أدخل رقم سري قوي",
	addNewPassword: "أضف الرقم السري الجديد",
	yourDoneWithShortlisting: "لقد إنتهيت من القائمة المختصرة.",
	yourChosenCandiatesAreNow:
		"يقوم المرشحون المختارون الآن بإعداد عروض الأسعار الخاصة بهم.",
	proDontHaveProjectsYet: "المحترف لا يوجد لديه مشاريع سابقة",
	contact: "تواصل",
	pleaseFillOutYourProfile: "يرجى ملء الحساب الشخصي الخاص بك",
	uploadYourPortofolio: "تحميل موجز الأعمال السابقة الخاصة بك",
	chooseYourWorkPreferences: "إختار نوع خدمتك",
	disclaimerImageShould:
		"يجب أن تكون الصورة واضحة ، ويجب أن تحتوي الرسومات على أبعاد وأقصى حجم للصورة هو 5 ميغا بايت",
	rejected: "مرفوض",
	sorryCouldntFindResult: "لا يوجد نتائج للبحث",
	youAreSearchingFor: "أنت تبحث عن ...",
	noArticlesYet: "لا يوجد مقالات حتي الآن",
	youAreA: "أنت ",
	pleaseChooseYourAccountType: "من فضلك اختار نوع الحساب",
	minProjectAreaIs: "ملاحظة: الحد الأدنى من مساحة المشروع هي 50 م²",
	termsOfUse: "شروط الإستخدام",
	// terms of use
	acceptanceOfTheTermsOfUse: "الموافقة على شروط الاستخدام",
	acceptanceOfTheTermsOfUseP1: `يرجى قراءة  شروط الاستخدام الماثلة ("الشروط") بعناية قبل الاستمرار في التسجيل أو استخدام أو الوصول إلى موقع بيدستر.مي ("بيدستر.مي"، "نحن") الإلكتروني (Bidster.me) ("الموقع") ، سواء كنت تفعل ذلك كزائرللموقع ("زائر"، "زوار") أو كمستخدم مسجل ("المستخدم" ،"المستخدمون") والمشار إليهم جمعاً بمصطلح ("أنت" و "أنتم").`,
	acceptanceOfTheTermsOfUseP2: `من خلال الوصول إلى استخدام أو التسجيل علي  موقعنا، فإنك تقبل وتوافق على الالتزام والامتثال بأحكام شروط الاستخدام الماثلة  كما يتم تعديلها من حين لأخر تمتثل لسياسة الخصوصية الخاصة بنا والتي يمكنك الوصول إليها عبر الرابط التالي: [بيدستر.مي]  `,
	acceptanceOfTheTermsOfUseP3:
		"وتعتبر سياسة الخصوصية جزءاً متمماً ومكملاً لتلك الشروط. ",
	acceptanceOfTheTermsOfUseP4:
		"تشكل هذه الشروط اتفاقية ملزمة قانوناً بينك و بين بيدستر.مي.",
	acceptanceOfTheTermsOfUseP5:
		"إذا كنت لا ترغب في الموافقة على أو الالتزام بهذه الشروط وسياسة الخصوصية الخاصة بنا، فأنك تُنصح بالخروج من موقعنا على الفور.",
	acceptanceOfTheTermsOfUseP6:
		"لا تغير هذه الشروط، بأي شكل من الأشكال، أي اتفاقيات أخرى وشروط أخرى تلتزم بها في علاقتك معنا (كأي شروط وأحكام تنطبق عليك وسياسة الخصوصية الخاصة بنا).",
	acceptanceOfTheTermsOfUseP7:
		"وفي حالة وجود أي تناقض بين هذه الشروط وأي شروط وأحكام قد تنطبق عليك (كمثال شروط وأحكام المتخصصين أو شروط وأحكام مالكي العقارات) فأن بنود الشروط والأحكام المعنية تسود علي هذه الشروط. ",
	changeOfTerms: "التغييرات في الشروط",
	changeOfTermsP1: `تحتفظ بيدستر.مي بالحق في مراجعة وتعديل هذه الشروط من وقت لآخر وفقًا لتقديرها المطلق. وتكون جميع التغييرات الواردة على تلك الشروط نافذة المفعول فور تحديثها على الموقع.  ولذلك يتوقع منك وتقوم بتحمل المسئولية الكاملة لزيارة الموقع والتأكد من تاريخ "أخر تعديل" للشروط كما هو موضح على الشروط.
ويعتبر الاستمرار في الوصول إلى أو استخدام أو التسجيل علي الموقع بمثابة موافقة منك على الامتثال لكافة تعديلات وتحديثات للشروط.`,
	eligability: "الأهلية",
	eligabilityP1: `لا يستهدف موقعنا المستخدمين دون سن 21 عامًا وعليه فأن استخدام الموقع سيكون متاحا فقط للمستخدمين الذين ينطبق عليهم السن المبين بالشروط الماثلة. 

من خلال الوصول أو استخدام أو التسجيل على منصاتنا، يقر المستخدم بأنه يتمتع بالأهلية القانونية للتعاقد مع بيدستر.مي (مما يشمل تلك الشروط) وأن أية بيانات مقدمة لأغراض استخدام الموقع صحيحة وقانونية وفقاً للشروط والقانون المطبق.

يقر ويوافق المستخدم بإخلاء مسئولية بيدستر.مي من الالتزام بالتحقق من أهلية أو صحة بيانات المستخدمين سواء تم الحصول عليها نتيجة الوصول أو استخدام الموقع أو الوصول للخدمات وإنها تعتبر تحت مسئولية كل مستخدم وحده.`,
	modificationsToBidster: "التعديلات المدخلة على منصة بيدستر.مي",
	modificationsToBidsterP1:
		"يوافق المستخدم على احتفاظ بيدستر.مي  بالحق في تطوير، سحب، تقييد، وقف أو تعديل الموقع أو أي من خصائصه أو إمكانيات الوصول أو الاستخدام للموقع أو الخدمات بصفة مؤقتة أو دائمة وفقاً لتقديرها المطلق ودون حاجة الى أي إشعار مسبق بذلك. ",
	modificationsToBidsterP2:
		"كما يوافق المستخدم على إخلاء مسئولية بيدستر.مي تجاه ممارسة أي من الحقوق والتصرفات المبينة أعلاه لأي سبب من الأسباب ولأي فترة من الفترات.",
	informationAboutYou: "المعلومات الخاصة بك ",
	informationAboutYouP1:
		"يمكن لبيدستر.مي أن تطلب منك تفاصيل تسجيل معينة أو معلومات أخرى حتي تمكنك من الوصول إلى الموقع  أو أياً من خدماتها (الخدمات المهنية و خدمات التقسيط و خدمات إدارة المشاريع، باستثناء صفحة الخدمات الأخرى). وفي هذا الصدد، فأنك تتعهد للشركة بتقديم معلومات دقيقة وصحيحة وحديثة وكاملة تحت كامل مسئوليتك.",
	informationAboutYouP2:
		"تخضع جميع المعلومات التي يتم جمعها ومعالجتها وتخزينها واستخداماتها ومشاركتها من قبل بيدستر.مي إلي سياسة الخصوصية الخاصة بنا والتي يمكن الوصول إليها من خلال الرابط التالي [Bidster.me]. ",
	informationAboutYouP3:
		"ويعتبر استخدام الموقع أو الخدمات بمثابة إقرار من المستخدم بالاطلاع على سياسة الخصوصية وموافقة منه لبيدستر.مي لاستخدام البيانات ومعالجتها وفقاً لسياسة الخصوصية.",
	intellectualPropertyRights: "حقوق الملكية الفكرية ",
	intellectualPropertyRightsP1:
		" الموقع وكامل محتوياتها وموادها وخصائصها ووظائفها (بما في ذلك على سبيل المثال لا الحصر جميع المعلومات والبرمجيات والشعار والنص والشاشات والرسوم البيانية والصور وأشرطة الفيديو والصوت وأي تصميم لها، و المشار إليها بمصطلح  'مواد الموقع' ) مملوكة حصريا وتحت سيطرة بيدستر.مي  وبالتالي فأن جميع مواد المنصات بيدستر.مي تعد من ممتلكاتها الفكرية المحمية بالقوانين و اللوائح المصرية. ",
	intellectualPropertyRightsP2:
		"ولا يحق لاي مستخدم أو طرف ثالث استخدام مواد الموقع أو نسخها أو معالجتها بأي شكل من الأشكال دون موافقة أو الحصول على تصريح كتابي مسبق من بيدستر.مي.",
	intellectualPropertyRightsP3:
		"موقعنا ومحتواها ومواد الموقع محمية بحقوق النشر والعلامات التجارية وبراءات الاختراع والأسرار التجارية.",
	intellectualPropertyRightsP4:
		"لا يجوز نسخ، تقليد أو استخدام مواد الموقع ، كلياً أو جزئياً، دون أذن كتابي مسبق من بيدستر.مي. ",
	intellectualPropertyRightsP5:
		"جميع الحقوق التي لم تمنح لك صراحةً تظل محفوظة لبيدستر.مي. ",
	intellectualPropertyRightsP6: `خضوعاً لهذه الشروط، تمنح بيدستر.مي مستخدميها بشكل محدد حق شخصي غير حصري وغير قابل للحوالة ومحدود وقابل للإلغاء في استخدام المنصات للاستخدام الشخصي. يبرر أي استخدام يتناقض مع السالف وقف بيدستر.مي لاستخدامك و / أو وصولك لمواقعها. `,
	warrantiesAndDisclaimer: "التعهدات والضمانات",
	warrantiesAndDisclaimerP1:
		"أنت تفهم أن بيدستر.مي لا تستطيع أن تضمن ولا تضمن أن الملفات المتاحة للتنزيل عبر الإنترنت أو المنصات ستكون خالية من الفيروسات أو أي رمز مدمر آخر. وبالتالي، فأنت مسئول تمامًا عن تنفيذ الإجراءات ونقاط التفتيش الكافية لتلبية متطلباتك الخاصة للحماية من الفيروسات. إن استخدامك لمنصاتنا ومحتواها وأي خدمة للعناصر التي تم الحصول عليها من خلال منصاتنا يكون على مسئوليتك الخاصة",
	limitations: "الحد من المسئولية",
	limitationsP1: `أنك تقر أن بيدستر.مي مجرد وسيط يهدف إلى ربط المستخدمين بمقدمي الخدمات المختلفين وليست طرف في أي اتصال فعلي بينهم أو أي اتفاق قد يتعاقدون عليه. 
وبالتالي لن نكون مسئولين عن أي إخفاقات أو تقصير ناشئ عن أداء أي طرف لالتزاماته.
كما لا تتحكم بيدستر.مي بصورة مباشرة أو غير مباشرة ولا تعتبر مسئولة عن الجودة أو التوقيت أو الشرعية أو عدم توفير أو إغفال أو دقة أو ملاءمة أو أي جانب آخر يمكن اتخاذ إجراء بشأنه.`,
	limitationsP2: `لن نتحمل مسئولية أو نقدم أي ضمان فيما يتعلق بدقة أو توقيت أو اكتمال أو ملاءمة المعلومات والمواد الموجودة أو المقدمة على الموقع لأي غرض معين.`,
	limitationsP3:
		"كما أنك تقر بأن مثل هذه المعلومات والمواد قد تحتوي على أخطاء وعليه فأنك توافق على اخلاء مسؤولية بيدستر.مي من المسئولية عن أي أخطاء من هذا القبيل إلى أقصى حد يسمح به القانون.",
	limitationsP4:
		"إن استخدامك لأي معلومات أو مواد واردة على الموقع يكون على مسئوليتك الخاصة تمامًا، ولن تكون بيدستر.مي مسئولة عنها بأي شكل من الأشكال. كما يقع على عاتقكم مسئولية ضمان استيفاء أي معلومات متاحة على الموقع لمتطلباتكم المحددة.",
	linkFromWebisite: "الروابط الواردة على موقعنا",
	linkFromWebisiteP1:
		"أنت تقر بأنه في حالة احتواء موقعنا على أي روابط تحيل المستخدمين إلى مواقع وأو منصات وموارد أخرى يوفرها الغير، سواء لغرض الإعلانات التجارية أم لا، لا تمتلك بيدستر.مي أي سيطرة ، سواء كانت مباشرة أو غير مباشرة، على محتويات تلك المواقع وأو منصات أو الموارد. ",
	linkFromWebisiteP2:
		"علاوة على ذلك، فانك توافق أن بيدستر.مي لن تكون مسئولة عن أي نزاع أو اختلاف أو مطالبة ناشئة عن إحالتك واستخدامك للمواقع وأو منصات المذكورة. إذا قررت الوصول إلى أي من مواقع الغير، فأنت تفعل ذلك بكامل مسئوليتك الخاصة وتقر بأن في هذه الحالة تُحكم جميع الأمور المتعلقة بجمع البيانات أو استخدامها أو تخزينها أو مشاركتها بسياسة الخصوصية و الشروط المعنية ذات الصلة كما يوفرها لك الغير.",
	termination: "الإنهاء ",
	terminationP1:
		"دون الإخلال بأي من هذه الشروط، تحتفظ بيدستر.مي بالحق، دون إشعار ووفقًا لتقديرها الوحيد، في إنهاء حقك في استخدام الموقع و/ أو منع وصولك إليه . ولا يشكل إخفاق بيدستر.مي أو تأخرها في اتخاذ مثل هذه الإجراءات تنازلاً عن حقوقها في إنفاذ هذه الشروط.",
	serverablity: "الاستقلالية",
	serverablityP1:
		"إذا اعتبر أي شرط من هذه الشروط غير قانوني أو باطل أو غير قابل للتنفيذ لأي سبب من الأسباب، فيجب اعتبار هذا الشرط قابلاً للفصل عن هذه الشروط ولا يؤثر على صحة أو قابلية إنفاذ أي شرط آخر من هذه الشروط.",
	language: "اللغة",
	languageP1:
		"تحررت تلك الشروط باللغتين العربية والإنجليزية وفي حالة وجود تعارض يسود النص العربي.",
	governingLawAndJurisdiction: "القانون واجب التطبيق ووسيلة حل المنازعات ",
	governingLawAndJurisdictionP1: `يجب أولاً تسوية جميع المسائل المتعلقة بمواقع بيدستر.مي وهذه الشروط، وأي نزاع و/أو اختلاف و/أو مطالبة ناشئة عنها بشكل غير رسمي وودي معنا و يكون ذلك بالقدر المسموح به بموجب القانون المطبق. 
 في حالة عدم التوصل إلى تسوية ودية في غضون فترة لا تقل عن ثلاثين (30) يوماً، يسوى أي نزاع أو خلاف و/أو مطالبة ناشئة عن ذلك عن طريق التحكيم وفقا لقواعد التحكيم الصادرة عن مركز القاهرة الإقليمي للتحكيم التجاري الدولي. يكون مكان التحكيم في جمهورية مصر العربية، وتكون لغة التحكيم هي الإنجليزية ويتولى التحكيم محكم واحد.`,
	governingLawAndJurisdictionP2:
		"تخضع هذه الاتفاقية و أي اتفاقيات أخري مبرمة بينك و بين بيدستر.مي لقوانين جمهورية مصر العربية، و بالأخص قانون حماية البيانات الشخصية رقم 151 لسنة 2020.  ",
	UtilitiesAndMoreWebsiteDisclaimer:
		"تنويه إخلاء مسئولية بيدستر.مي عن دليل  'الخدمات الأخرى'",
	UtilitiesAndMoreWebsiteDisclaimerP1:
		"صفحة ('الخدمات الأخرى') هذه هي صفحة تقوم بيدستر.مي من خلالها بالتعامل في حدود صفتها كوسيط بين كلٍ من مستخدمي الموقع المسجلين ('المستخدمين') و/أو زوار الموقع الغير مسجلين ('الزوار') و الذين يشار إليهم جمعاً بمصطلح ('المستخدمين و غيرها'، 'أنت') ومن ناحية أخرى بين مقدمي الخدمات (' مقدمي الخدمات').",
	UtilitiesAndMoreWebsiteDisclaimerP2:
		"تحتوي صفحة الخدمات الأخرى على عدة فئات ('الفئة “،' الفئات') مثل؛ التنظيف العميق ومكافحة الحشرات وشركات النقل والفرش وخدمات الحدائق وخدمات المنازل الذكية وأنظمة الأمن وأخصائي الكهرباء والنجارة والسباكة.",
	UtilitiesAndMoreWebsiteDisclaimerP3:
		"تقوم بيدستر.مي في كل فئة بربط تفاصيل الاتصال والدليل على منصتنا ('المنصة') لكلٍ من مقدمي الخدمات، مما يتيح لمستخدمي الخدمات الأخرى فرصة الاختيار المباشر و الحر بين مقدمي الخدمات الواردة بيناتهم علي الصفحة و التواصل مع واحد (1) أو أكثر منهم بهدف الاستفسار عن أي خدمة يريدون الحصول عليها و/أو تعيين مقدم أو أكثر من مقدمي الخدمات. ",
	UtilitiesAndMoreWebsiteDisclaimerP4:
		"يرجى العلم أن بيدستر.مي تقوم بتوفر تفاصيل الاتصال الخاصة بمقدم الخدمة ، كما تم تحديثها أخيرًا وإخطارنا بها من قبل مقدم الخدمة المعني. وبالتالي، فأن بيدستر.مي لن تكون مسئولة عن أي مسألة تتعلق، بشكل مباشر أو غير مباشر، بصحة أو دقة تفاصيل الاتصال الخاصة بمقدم الخدمة أو أي تغيير في تفاصيل الاتصال الخاصة بمقدم الخدمة التي لم يتم إخطارنا بها.",

	UtilitiesAndMoreWebsiteDisclaimerP5:
		"وفي هذا الصدد، يرجى العلم بأن بيدستر.مي لن تكون طرف و لن تشارك في أي اتصال أو اجتماعات أو اتفاقات أو أي عمل مماثل يبرم بينك وبين مقدمي الخدمات. وعلى وجه الخصوص، فأن بيدستر.مي ليست مسئولة ولا تتحكم، سواء بشكل مباشر أو غير مباشر، في المبلغ المستحق مقابل الخدمة وطريقة الدفع التي تتفق عليها مع مقدم الخدمة المعني. ",
	UtilitiesAndMoreWebsiteDisclaimerP6:
		"و وبناءً علي ذلك، لن تكون بيدستر.مي طرف في علاقتك مع مقدمي الخدمات أو مسئولة عن أي إخفاقات أو تخلفات ناشئة عن اتصالاتك أو اتفاقاتك مع مقدمي الخدمات. علاوة على ذلك، من خلال الشروع في الوصول الي و/أو استخدام صفحتنا للخدمات الأخري، فإنك تقر وتوافق على إخلاء مسئولية بيدستر.مي من أي التزام مرتبط بجودة الخدمات المطلوبة أو توقيتها أو شرعيتها أو عدم توفيرها أو الإغفالات أو الدقة أو الملاءمة أو أي جانب آخر يمكن اتخاذ إجراء بشأنه.",
	UtilitiesAndMoreWebsiteDisclaimerP7:
		"برجاء العلم أنه لا يمكنك الجمع بين طلبك و/أو استخدام الخدمات الواردة علي هذه الصفحة مع أي من الخدمات الأخرى التي تقدمها بيدستر.مي (الخدمات المهنية وإدارة المشاريع و خدمة التقسيط).	وبالتالي، فأن تقديم طلبك لمقدمي الخدمة و/أو استخدامك لتلك الخدمات يجب أن يتم بشكل منفصل، ويكون تنظيمه مستقل عن أي خدمات أخرى قد تطلبها من بيدستر.مي وذلك في جميع الأمور التي تشمل على سبيل المثال لا الحصر: الرسوم المستحقة عن الخدمة المطلوبة والتزامات كلٍ من بيدستر.مي  والالتزامات الخاصة بك، وأي مدة زمنية متفق عليها لإتمام الخدمة المطلوبة  كما تخضع كل خدمة للشروط والأحكام الخاصة بها والتي تقوم بالموافقة عليها.",
	pleaseRateBidster: "يرجى تقييم بيدستر فيما يتعلق بما يلي:",
	rateYourExperience: "قيِّم تجربتك",
	survey: "استبيان",
	waitingForQuotation: "في انتظار عرض الأسعار*",
	proIsPreparingQuotaion: "المحترف يحضر عرض الأسعار",
	downloadQuotation: "تحميل عرض السعر",
	pleaseFillProjectStatus: "من فضلك أدخل حالة المشروع",
	areUSureYouDontWantReshortlisting:
		"هل أنت متأكد أنك لا ترغب في إعادة إختيار القائمة المختصرة؟",
	areYouSureYouWantToReshortlising:
		"هل أنت متأكد أنك لا تريد إعادة عملية إختيار القائمة المختصرة؟",
	ifYouClickYesThisProjectWillBeClosed:
		" إذا قمت بالنقر على نعم، سيتم إغلاق هذا المشروع.",
	yesCloseIt: " نعم، أغلقه.",
	"the3CandidatesDidn'tSubmit": "لم يقدم الثلاثة مرشحين عرضهم",
	wouldYouLikeToReshortlisting:
		"هل ترغب في إعادة قائمة مختصرة لثلاثة مرشحين آخرين؟",
	noThanks: "لا، شكرًا",
  paidSuccessfullyAndPointsAdded: "تم الدفع بنجاح و تم إضافة النقاط",
  errorHappendInThePayment: "حدث خطأ اثناء الدفع"
};
